<template>
  <div class="page">
    <div class="title">积分信息</div>
    <div class="main">
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">我的积分</span>
        </div>
        <div class="n2">
          <div class="item">
            <div class="itk">
              <span class="iimcount pcolor">{{ integral }}</span> 积分
            </div>
            <div class="itv action">获取更多积分 ></div>
          </div>
        </div>
      </div>
      <!-- <div class="box">
        <div class="ops">
          <div class="btngrp">
            <button
              @click="modulechange(0)"
              class="btn btn_l"
              :class="currentselect === 0 ? 'active' : ''"
            >
              全部记录
            </button>
            <button
              @click="modulechange(1)"
              class="btn btn_c"
              :class="currentselect === 1 ? 'active' : ''"
            >
              收入历史
            </button>
            <button
              @click="modulechange(2)"
              class="btn btn_r"
              :class="currentselect === 2 ? 'active' : ''"
            >
              支出历史
            </button>
          </div>
          <div class="pageinfo">
            <el-pagination
              :total="5"
              layout="total,prev, pager, next"
            ></el-pagination>
          </div>
        </div>
        -->
      <div class="box">
        <div class="ops">
          <div class="pageinfo">
             <div class="btngrp">
            <button
              @click="modulechange(0)"
              class="btn btn_l"
              :class="currentselect === 0 ? 'active' : ''"
            >
              全部记录
            </button>
            <button
              @click="modulechange(1)"
              class="btn btn_c"
              :class="currentselect === 1 ? 'active' : ''"
            >
              收入历史
            </button>
            <button
              @click="modulechange(2)"
              class="btn btn_r"
              :class="currentselect === 2 ? 'active' : ''"
            >
              支出历史
            </button>
          </div>
            <el-pagination
              layout="total,prev, pager, next"
              @current-change="pagechange"
              :current-page="pageinfo.pagenum"
              :page-size="pageinfo.pagesize"
              :total="pageinfo.total"
            ></el-pagination>
          </div>
        </div>
        <div class="datainfo" v-if="currentselect === 0">
          <el-table
            :data="intergralList"
            :header-cell-style="{ background: '#e8e8e8' }"
            :row-style="{ height: '55px' }"
            border
            row-key="id"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="序号"
              align="center"
              width="60px"
            ></el-table-column>
            <el-table-column
              prop="uid"
              label="个人id"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="注册时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="value"
              label="积分"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      intergralList: [],
      currentselect: 0, //0-全部。1-收入。2-支出
      integral: 0,
      pageinfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getuserinfo();
    this.getintegralInfo();
  },
  methods: {
    modulechange(n) {
      this.currentselect = n;
    },
    getuserinfo() {
      this.axios.get("/users/getuserinfo").then((res) => {
        this.integral = res.data.integral;
      });
    },
    getintegralInfo() {
      this.axios
        .get("/users/integralRecordList", {
          params: {
            pageNum: this.pageinfo.pageNum,
            pageSize: this.pageinfo.pageSize,
          },
        })
        .then((res) => {
          console.log("dsafdsf", res);
          this.intergralList = []
          if (res.code === this.global.CODE.SUCCESS) {
            this.intergralList = res.data.list;
            this.pageinfo.total = res.data.total;
            this.pageinfo.pageNum = res.data.pageNum;
          }
        })
        .catch((error) => {
          this.$message.error(error);
         
        });
    },
    pagechange(v) {
      console.log("sfafasf",v);
      this.pageinfo.pageNum = v;
      this.getintegralInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  font-weight: bold;
  color: #313131;
}

.main {
  min-width: 780px;
  background-color: #fff;
  border-radius: 12px;
  margin: 15px 0;
  padding: 28px;
}

.main .box {
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 #ddd;
  margin-top: 30px;
  padding: 20px;
  color: #666;
}

.box .n1 {
  display: flex;
  align-items: center;
}

.box .n1 .hlhs {
  width: 2px;
  height: 16px;
}

.box .n1 .txt {
  margin-left: 10px;
}

.box .n2 .item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 20px;
}

.iimcount {
  font-size: 16px;
}

.box .n2 .item .action:hover {
  cursor: pointer;
}

.box .n2 .item .itv {
  margin-left: 20px;
}

// .box .ops {
 
//   justify-content: space-between;
// }

.box .ops .btngrp .btn.active {
  color: #ff6a00;
  border: 1px solid #ff6a00;
}

button:hover {
  cursor: pointer;
}

.box .ops .btngrp .btn {
  height: 28px;
  padding: 0 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
}

.box .ops .btngrp .btn_l {
  border-radius: 8px 0 0 8px;
  border-right: 0;
}

.box .ops .btngrp .btn_c {
  border-radius: 0;
}

.box .ops .btngrp .btn_r {
  border-radius: 0 8px 8px 0;
  border-left: 0;
}

.box .ops .pageinfo {
  display: flex;
  justify-content: space-between;
}

.box .tabtitle {
  margin-top: 16px;
  background-color: #e8e8e8;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
}

.box .tabdata {
  min-height: 300px;
}
</style>